/* Define the footer background and layout properties */
footer {
  background-color: #333533; /* a darker shade of blue */
  padding: 1rem 2rem; /* vertical and horizontal padding */
  display: flex; /* use flexbox for layout */
  justify-content: space-between; /* space items apart */
  align-items: center; /* center items vertically */
  width: 100%; /* full width */
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2); /* subtle shadow for depth */
  position: relative;
  z-index: 10; /* Ensure footer stays on top of other content, if any overlaps occur */
  border-top: 2px solid #BAC6D8; /* a soft border at the top */
  box-sizing: border-box; /* new property */
  overflow: hidden; /* new property */
}

/* Style footer text and links container */
footer div {
  display: flex; /* use flexbox for layout */
  align-items: center; /* center items vertically */
}

/* Style footer paragraph */
footer p {
  color: #BAC6D8; /* a soft shade of blue */
  margin: 0 1rem 0 0; /* right margin for spacing */
  font-size: 0.9rem; /* slightly smaller font size */
}

/* Style footer links */
footer a {
  color: #BAC6D8;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 0.9rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

footer a:before {
  margin-right: 0.5rem;
}

/* Add hover effect for footer links */
footer a:hover {
  color: #ffffff; /* change to white on hover */
}
