.grid-instruction-container {
    text-align: center;
    background: #242423; /* Softer blue gradient background */
    padding: 20px 0;
    border-bottom: 3px solid #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.grid-instruction {
    margin: 0 auto;
    padding: 0 30px;
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.6;
    animation: fadeIn 1.5s ease-out;
}
.why-donate {
    background-color: var(--white);
    padding: var(--spacing-base);
    margin: var(--spacing-base) auto;
    max-width: 1000px;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    text-align: center;
    color: #0c2731; /* Dark blue color for the text */
}

.why-donate h2 {
    color: #0c2731; /* Dark blue color for the heading */
    margin-bottom: var(--spacing-small);
}

.why-donate p {
    color: #343A40; /* Dark grey color for the text */
    margin-bottom: var(--spacing-small);
    font-size: var(--font-size-base);
}
  

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.grid-instruction:before {
    content: '\1F50E';
    display: inline-block;
    margin-right: 15px;
    font-size: 1.8rem;
    vertical-align: middle;
}

/* Adding styles for the "Need Help?" button */
.help-button {
    background-color: #52beec; /* Blue color to match your header */
    color: #ffffff; /* White text for contrast */
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.help-button:hover {
    background-color: #3a9fd1; /* Slightly darker shade of blue for hover effect */
}

/* Styling for the main content area */
.main-content {
    padding: 20px;
    text-align: center; /* Center content, adjust as needed */
}

@media (max-width: 768px) {
    .grid-instruction-container {
        padding: 15px;
    }

    .grid-instruction {
        padding: 0 15px;
        font-size: 0.9rem;
        line-height: 1.5;
    }

    .grid-instruction:before {
        font-size: 1.6rem;
    }

    /* Adjustments for the "Need Help?" button on smaller screens */
    .help-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }

    /* Adjustments for the main content area on smaller screens */
    .main-content {
        padding: 15px;
    }
}
