.how-to-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.how-to-popup {
  background-color: #fff; /* Softer blue for a modern look */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px; /* Adjusted max width for better layout */
}

/* Update text color for headings */
.how-to-popup h1,
.how-to-popup h2,
.how-to-popup h3,
.how-to-popup h4,
.how-to-popup h5,
.how-to-popup h6 {
  color: #242423; /* Dark blue text color */
}

/* Update text color for paragraphs and list items */
.how-to-popup li {
  color: #242423;
  text-align: left /* Dark blue text color */
}

.how-to-popup p{
  color: #242423;
}

.how-to-popup button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #52beec; /* Blue color to match your header */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.how-to-popup button:hover {
  background-color: #3a9fd1; /* Slightly darker shade of blue for hover effect */
}

.how-to-image {
  max-width: 55%;
  margin-top: 5px;
  border-radius: 8px;
}