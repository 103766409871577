.grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 30px auto;
  max-width: 950px; /* Maximum width constraint */
  max-height: 950px; /* Maximum height constraint */
  width: 100%; /* Allows the container to scale down on smaller screens */
  height: auto; /* Height adjusts based on content, maintaining aspect ratio */
}

.grid-image {
  width: 100%; /* Image scales with the container */
  height: auto; /* Maintain aspect ratio */
  max-width: 950px; /* Maximum width constraint */
  max-height: 950px; /* Maximum height constraint */
  display: block; /* Ensures the image doesn't add extra space below it */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(50, 1fr); /* For a 50x50 grid */
  grid-template-rows: repeat(50, 1fr);
  width: 100%; /* Match the parent's size */
  height: 100%;
}

.grid-box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative; /* Needed for positioning the tooltip */
}

.box-details {
  position: absolute;
  bottom: 10px; /* Position the details at the bottom of the grid */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white background */
  border: 1px solid #ddd; /* Light grey border */
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Soft shadow for depth */
  text-align: center;
  z-index: 20; /* Ensure it's above the grid boxes */
}

.tooltip {
  position: fixed; /* Positioned relative to the viewport */
  background-color: #f9f9f9; /* Light background for a minimalist look */
  color: #333; /* Darker text for contrast */
  padding: 8px 12px; /* Adjust padding for content */
  border-radius: 4px; /* Rounded corners */
  font-size: 14px; /* Slightly larger font for readability */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  z-index: 1000; /* High z-index to ensure it appears above other elements */
  pointer-events: none; /* Avoids interfering with other mouse events */
}

@media (max-width: 768px) {
  .grid-container, .grid-image {
    max-width: 100%;
    max-height: none;
    min-width: 300px; /* Allows scaling down to a smaller size on moderate screens */
    min-height: 300px; /* Adjusts minimum height for moderate screens */
  }
}

@media (max-width: 480px) {
  .grid-container, .grid-image {
    min-width: 200px; /* Allows further scaling down on very small screens */
    min-height: 200px; /* Adjusts minimum height for very small screens */
  }
}
