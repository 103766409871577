header {
  background-color: #f4fdfc; /* Softer blue for a modern look */
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  flex-wrap: wrap;
}

.logo {
  max-height: 120px !important;
}

.logoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logoText {
  color: #040404; /* White text for contrast */
  font-size: calc(1rem + 1.5vw);
  margin-left: 10px;
  white-space: nowrap;
}

.header-right-link,
.header-right-link:hover, /* Ensure no change on hover */
.header-right-link:focus { /* Ensure no change on focus for accessibility */
  text-decoration: none; /* Removes underline from the link */
  color: inherit; /* Maintain the same text color */
}

.header-right {
  background-color: #EDEFF3; /* Light neutral color for secondary background */
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-action,
.header-action-buy {
  color: #333333; /* Dark gray for readability */
  margin: 5px 0;
  padding: 2px 5px;
  font-size: 1rem;
  text-align: center;
}

.header-action-buy {
  color: blue; /* Make the buy action stand out */
}

.header-middle p {
  /* Retain the existing text styling for the paragraph */
  color: #0c2731; /* White text for contrast */
  font-size: 1.7vw; /* Starting responsive font size */
  min-font-size: 16px; /* Minimum font size */
  max-font-size: 24px; /* Maximum font size */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); /* Text shadow for better readability */
}

.give p {
  /* Retain the existing text styling for the paragraph */
  color: #FFFFFF; /* White text for contrast */
  font-size: 1.7vw; /* Starting responsive font size */
  min-font-size: 16px; /* Minimum font size */
  max-font-size: 24px; /* Maximum font size */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5); /* Text shadow for better readability */
  transition: color 0.5s ease-in-out; /* Smooth color transition, if needed */
}

.highlight {
  animation: colorChange 10s infinite; /* Adjust duration as needed */
  font-weight: bold; /* Optional, for emphasis */
}

@keyframes colorChange {
  0% { color: #ff0000; } /* Red */
  25% { color: #00ff00; } /* Green */
  50% { color: #0000ff; } /* Blue */
  75% { color: #ff00ff; } /* Magenta */
  100% { color: #ff0000; } /* Red again */
}

.give:hover {
  color: pink; /* Or any color */
  transition: color 0.5s ease-in-out; /* Smooth color transition */
}

@media (max-width: 1024px) { /* Adjust this value based on your content's needs */
  .header-middle {
    display: none; /* Hide the header-middle content on mobile */
  }
}

/* Adjustments for mobile */
@media (max-width: 768px) {
  header {
    flex-direction: column;
    padding: 1rem;
    align-items: center;
  }

  .logoContainer, .header-right {
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .header-middle {
    display: none; /* Hide the header-middle content on mobile */
  }

  .logo {
    margin: 0 auto;
    max-height: 160px;
  }

  .header-right {
    margin: auto;
    max-width: 95%;
    overflow: hidden;
  }
}
