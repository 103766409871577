/* Define color palette and common dimensions as CSS variables for easy maintenance and consistency */
:root {
    --primary-blue: #007BFF;
    --light-blue: #E7F1FF;
    --dark-blue: #003366; /* Updated to variable for header/footer colors */
    --accent-blue: #00AEEF;
    --soft-blue: #D9EFFF;
    --white: #FFFFFF;
    --soft-grey: #F0F0F0;
    --dark-grey: #343A40;
    --content-max-width: 1000px; /* Max width for content */
    --default-padding: 20px; /* Default padding */
    --default-margin: 20px; /* Default margin */
    --section-margin-bottom: 40px; /* Margin bottom for sections */
    --image-max-width: 200px; /* Max width for images */
}

.how-it-works-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: #242423; /* Softer blue gradient background */

}

.how-it-works-content {
    flex: 1;
    padding: var(--default-padding);
    max-width: var(--content-max-width);
    margin: auto;
}

/* Unified heading styles with variables */
.how-it-works-content h1 {
    text-align: center;
    color: #f4fdfc;
    margin-bottom: var(--default-margin);
}

/* Consistent bottom margin for all sections */
.customization-section, .support-section, .tiered-system-section {
    margin-bottom: var(--section-margin-bottom);
    color: #242423;
}

.customization-section h2, .tiered-system-section h2{
    color: #242423;
}

.intro-section, .support-section{
    color: #f4fdfc;
}

/* Flex layout for content wrappers */
.intro-content-wrapper, .support-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--default-margin);
}

/* Text alignment and padding for content sections */
.intro-content, .support-content {
    flex: 1;
    text-align: left;
    padding-right: var(--default-padding);
}

/* Image styling with variables */
.intro-image, .support-image {
    max-width: var(--image-max-width);
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    margin: 0 var(--default-margin);
}

/* Background and box shadow for highlighted sections */
.tiered-system-section, .customization-section {
    background-color: #f4fdfc; /* Using the light blue for separation */
    padding: var(--default-padding);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* List styling with removal of default list styling */
.tier-list, .customization-section ol, .customization-section ul {
    list-style-type: none;
    padding: 0;
    margin-top: var(--default-margin);
}

/* List item styling with variables */
.tier-list li, .customization-section li {
    background-color: var(--white);
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .how-it-works-content {
        padding: 10px;
        max-width: 95%;
    }

    .intro-content-wrapper, .support-content-wrapper, .tiered-system-section {
        flex-direction: column;
    }

    .intro-content, .support-content, .intro-image, .support-image {
        width: 100%;
        padding-right: 0;
        text-align: center;
        margin-bottom: var(--default-margin);
    }

    h2 {
        font-size: 1.5rem;
    }

    .tier-list li, .customization-section li {
        font-size: 0.9rem;
    }
}
