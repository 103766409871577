:root {
    --primary-blue: #007BFF;
    --light-blue: #E7F1FF;
    --dark-blue: #003366;
    --accent-blue: #00AEEF;
    --soft-blue: #D9EFFF;
    --white: #FFFFFF;
    --soft-grey: #F0F0F0;
    --dark-grey: #343A40;
    --font-size-base: 16px;
    --font-size-large: 18px;
    --font-size-small: 14px;
    --spacing-base: 20px;
    --spacing-small: 10px;
    --border-radius: 5px;
  }
  
  body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .our-cause-page {
    padding: var(--spacing-base);
    background: #242423;
    color: var(--dark-grey);
    box-sizing: border-box;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .our-cause-page h1 {
    text-align: center;
    color: #f4fdfc;
    margin-bottom: var(--spacing-base);
  }
  
  .cause-section {
    background-color: var(--white);
    padding: var(--spacing-base);
    margin: var(--spacing-base) auto;
    max-width: 1000px;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  
  .cause-section h2 {
    color: #0c2731;
    margin-bottom: var(--spacing-small);
  }
  
  .cause-section p, .cause-section ul {
    font-size: var(--font-size-base);
    text-align: justify;
    line-height: 1.6;
    margin-bottom: var(--spacing-small);
    color: var(--dark-grey);
  }
  
  .cause-section ul {
    padding-left: 20px;
  }
  
  .cause-section ul li {
    margin-bottom: var(--spacing-small);
    list-style-type: disc;
  }
  
  .cause-section a {
    color: var(--accent-blue);
    text-decoration: none;
  }
  
  .cause-section a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .our-cause-page {
      padding: var(--spacing-small);
    }
  
    .cause-section {
      padding: var(--spacing-small);
      margin: var(--spacing-small) auto;
    }
  
    .cause-section p, .cause-section ul {
      font-size: var(--font-size-small);
    }
  
    .cause-section h1, .cause-section h2 {
      font-size: 1.2em;
    }
  }
  