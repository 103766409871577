/* Color Scheme Variables */
:root {
    --primary-blue: #007BFF;
    --light-blue: #E7F1FF;
    --dark-blue: #003366;
    --accent-blue: #00AEEF;
    --soft-blue: #D9EFFF;
    --white: #FFFFFF;
    --soft-grey: #F0F0F0;
    --dark-grey: #343A40;
    --font-size-base: 16px;
    --font-size-large: 18px;
    --font-size-small: 14px;
    --spacing-base: 20px;
    --spacing-small: 10px;
}

/* Blog Post Page */
.blog-post-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: var(--spacing-base); /* Added to ensure content doesn't go edge to edge */
    background-color: var(--soft-grey); /* Soft background for the entire page */
}

/* Blog Post Header */
.blog-post-header {
    background-color: var(--dark-blue);
    color: var(--white);
    padding: var(--spacing-base);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-post-header h1 {
    color: white; /* Color for H1 titles within the header */
}

/* Blog Post Content */
.blog-post-content {
    flex: 1; /* Take up remaining space */
    padding: var(--spacing-small); /* Padding around content */
}

/* Sections within Blog Post Content for better readability */
.introduction-section, .founders-journey-section, .platform-impact-section, .future-vision-section {
    margin-bottom: 50px; /* Space between sections */
}

/* Blog Post Footer */
.blog-post-footer {
    color: var(--white);
    text-align: center;
    padding: var(--spacing-small);
}

.back-to-blog {
    color: var(--accent-blue);
    text-decoration: none;
    padding: var(--spacing-small);
    border-radius: 5px;
    transition: background-color 0.3s ease;
}
.blog-post-content p {
    text-align: left; /* Ensures all paragraphs in the blog post content are left-aligned */
    color: #242423;
}

.blog-post-content h2 {
    color: #242423;
}

.back-to-blog:hover {
    background-color: var(--soft-blue); /* Soft hover effect for back link */
}

/* Responsive Design Adjustments */

