.sub-nav {
    background-color: #333533; /* Slightly darker shade for contrast */
    padding: 0.5rem 2rem; /* Smaller padding */
    display: flex;
    justify-content: center; /* Center the nav items */
  }
  
  .sub-nav ul {
    list-style-type: none;
    display: flex;
    gap: 0.5rem; /* Reduced gap */
    margin: 0;
    padding: 0;
  }
  
  .sub-nav a {
    color: #fff;
    text-decoration: none;
    font-weight: 400; /* Lighter font weight */
    padding: 0.25rem 0.5rem; /* Smaller padding */
    border-radius: 0.25rem;
    transition: background-color 0.3s;
  }
  
  .sub-nav a:hover, .subNavLinkActive {
    background-color: rgba(255, 255, 255, 0.1);
    color: #F5CB5C; /* Highlight color for active/hover state */
  }
  