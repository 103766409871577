:root {
    --primary-blue: #007BFF;
    --light-blue: #E7F1FF;
    --dark-blue: #003366;
    --accent-blue: #00AEEF;
    --soft-blue: #D9EFFF;
    --white: #FFFFFF;
    --soft-grey: #F0F0F0;
    --dark-grey: #343A40;
    --border-radius: 10px;
    --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    --transition: all 0.3s ease;
    --image-size: 150px;
  }
  
  .blog-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #242423;
    padding: 20px;
  }
  
  .blog-content {
    flex: 1;
    max-width: 800px;
    margin: auto;

  }
  
  .blog-post {
    background-color: #FFFFFF;
    border-radius: var(--border-radius);
    border: 1px solid var(--soft-grey);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .post-image {
    width: calc(var(--image-size) - 20px);
    height: calc(var(--image-size) - 20px);
    object-fit: contain;    margin: 15px;
    border-radius: var(--border-radius);
  }
  
  .post-content {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .post-title {
    color: var(--dark-blue);
    margin-top: 0;
  }
  
  .post-metadata {
    font-size: 0.9rem;
    color: var(--dark-grey);
    margin-bottom: 10px;
  }
  
  .post-body {
    line-height: 1.6;
  }
  @media (max-width: 768px) {
    .blog-post {
      flex-direction: row; /* Keep the layout as a row */
      align-items: center; /* Center items vertically */
      justify-content: space-between; /* Distribute space between items */
      padding: 10px 0; /* Add some padding for spacing */
    }
  
    .post-image {
      width: 100px; /* Fixed width for the image */
      height: 60px; /* Fixed height for the image, adjust as needed for your aspect ratio */
      object-fit: contain; /* Ensure the entire image fits within the given dimensions */
      margin: 5px; /* Reduce margin on smaller screens */
      flex-shrink: 0; /* Prevent the image from shrinking too much */
    }
  
    .post-content {
      flex: 1; /* Allow the content to fill the remaining space */
      padding: 0 10px; /* Adjust padding for spacing */
      max-width: calc(100% - 120px); /* Adjust the width to account for the image and some spacing */
    }
  
    .post-title, .post-metadata, .post-body {
      text-align: left; /* Align text to the left for consistency */
    }
  }
  
  
  
  /* Link Styles */
  .blog-post-link:hover,
  .blog-post-link:hover .post-metadata:hover {
    text-decoration: none; /* Removes underline effect and prevents text color change on hover */
    color: inherit; /* Keeps the text color unchanged on hover */
  }
  