:root {
    --primary-blue: #007BFF;
    --light-blue: #E7F1FF;
    --dark-blue: #003366;
    --accent-blue: #00AEEF;
    --soft-blue: #D9EFFF;
    --white: #FFFFFF;
    --soft-grey: #F0F0F0;
    --dark-grey: #343A40;
    --font-size-base: 16px;
    --font-size-large: 18px;
    --font-size-small: 14px;
    --spacing-base: 20px;
    --spacing-small: 10px;
    --border-radius: 5px;
}

.faq-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #242423;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.faq-content {
    flex: 1;
    padding: var(--spacing-base);
    max-width: 800px;
    margin: 0 auto;
}

.faq-content h1, h2 {
    text-align: center;
    color: #f4fdfc;
    margin-bottom: var(--default-margin);
}

.faq-section {
    margin-top: var(--spacing-base);
}

.faq-item {
    background-color: var(--white);
    padding: var(--spacing-base);
    margin-bottom: var(--spacing-base);
    border-radius: var(--border-radius);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-item h3 {
    color: #0c2731;
    margin-bottom: var(--spacing-small);
}

.faq-item h3 strong {
    font-size: var(--font-size-large);
}

.faq-item p {
    font-size: var(--font-size-base);
    margin-top: var(--spacing-small);
}

.faq-item p strong {
    font-weight: bold;
}

.faq-more-questions {
    margin-top: 40px;
    text-align: center;
}

.faq-more-questions p {
    color: #f4fdfc;
}

.faq-more-questions a {
    color: var(--accent-blue);
    text-decoration: none;
}

.faq-more-questions a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .faq-content {
        padding: var(--spacing-small);
    }

    .faq-item {
        padding: var(--spacing-small);
    }

    .faq-item h3 strong, .faq-item p strong {
        font-size: var(--font-size-small);
    }
}
